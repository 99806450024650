/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import UTMProvider from "./src/components/utmcontext"

export const registerServiceWorker = () => true
export const wrapPageElement = UTMProvider
export const onInitialClientRender = () => {
  if (typeof window !== "undefined") {
    if (window.location.hash) {
      const el = document.querySelector(window.location.hash)
      if (el) {
        el.scrollIntoView()
      }
    }
  }
}
