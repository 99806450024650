import React, { createContext } from "react"
import querystring from "query-string"
import { WrapPageElementBrowserArgs } from "gatsby"

export type UTMContextType = {
  utm_medium?: string
  utm_campaign?: string
  utm_source?: string
  utm_term?: string
}

export const UTMContext = createContext<UTMContextType>({})
const checkParams = (params?: string, newParams?: string): string | undefined =>
  (newParams && !params) || params !== newParams ? newParams : params

const Layout: React.FC = ({ children, ...props }) => {
  const [params, setParams] = React.useState<UTMContextType>({})
  React.useEffect(() => {
    const p = querystring.parse(window.location.search)
    setParams({
      utm_medium: checkParams(
        params.utm_medium,
        (p.utm_medium as string) || undefined
      ),
      utm_campaign: checkParams(
        params.utm_campaign,
        (p.utm_campaign as string) || undefined
      ),
      utm_source: checkParams(
        params.utm_source,
        (p.utm_source as string) || undefined
      ),
      utm_term: checkParams(
        params.utm_term,
        (p.utm_term as string) || undefined
      ),
    })
  }, [])
  return <UTMContext.Provider value={params}>{children}</UTMContext.Provider>
}

const wrapPageElement: React.FC<WrapPageElementBrowserArgs> = ({
  element,
  props,
}) => <Layout {...props}>{element}</Layout>

export default wrapPageElement
